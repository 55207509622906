<template>
  <div v-if="init">
    
    <div v-if="noData" class="text-center mt-10">
      削除された、もしくはプロジェクトに含まれていないノートです。
    </div>
    <div v-else class="d-flex">
      <!-- 参照エリア -->
      <strategy-parts-ref-bar
        @click-expand="expand = $event"
        :noteMode="true"
      ></strategy-parts-ref-bar>
      <div v-show="!expand" class="strategy-overview-wrap mx-auto">
        <!-- ノートエディタ -->
        <div class="strategy-overview">
          <!-- ツールバー -->
          <v-toolbar tile flat dense color="toolbar white px-0">
            <v-btn text color="btPrimary" dark @click="$router.back()" class="ml-n5">
              <v-icon size="32" left>mdi-chevron-left-circle-outline</v-icon>
              <span class="black--text">前のページに戻る</span>
            </v-btn>
            <v-spacer></v-spacer>
            <div v-if="hasWritePermission">
              <v-btn v-if="editing" depressed class="btAccent white--text" small @click="save()" :disabled="readonly">
                <v-icon small class="pr-2">mdi-content-save</v-icon>
                <span>保存して終了</span>
              </v-btn>
              <v-btn v-else depressed class="btAccent white--text" small @click="toggleEdit()" :disabled="readonly">
                <v-icon small class="pr-2">mdi-pencil</v-icon>
                <span>編集</span>
              </v-btn>
            </div>
          </v-toolbar>
      
          <!-- タイトル -->
          <div class="mx-auto pt-5">
            <!-- 編集時 -->
            <v-text-field
              v-if="editing"
              v-model="data.overview.title"
              autofocus
              height="55"
              placeholder="タイトルを入力してください"
              class="text-h5"
            />
            <!-- 閲覧時 -->
            <div v-else class="text-h5">{{ data.overview.title }}</div>
          </div>
          <!-- カテゴリ・ステータス -->
          <!-- 編集時 -->
          <v-row class="pt-2 justify-space-start" v-if="editing">
            <v-col cols="5">
              <v-combobox
                v-model="data.overview.category"
                :items="MasterData.overviewCategory"
                item-text="overviewCategory"
                item-value="overviewCategory"
                :return-object=false
                outlined
                dense
                :menu-props="{ maxHeight: 170 }"
                label="カテゴリー"
              />
            </v-col>
            <v-col cols="3">
              <v-select
                v-model="data.overview.inputStatus"
                :items="['編集中', '完了']"
                :return-object=false
                outlined
                dense
                :menu-props="{ maxHeight: 170 }"
                label="ステータス"
              />
            </v-col>
          </v-row>
          <!-- 閲覧時 -->
          <div class="d-flex flex-wrap pt-5 text-subtitle-2" v-else no-gutters>
            <div class="pe-5">
              <div class="font-weight-bold text-caption">カテゴリ</div>
              <div>{{ !!data.overview.category ? data.overview.category : 'なし'  }}</div>
            </div>
            <div class="pe-5">
              <div class="font-weight-bold text-caption">ステータス</div>
              <div>{{ data.overview.inputStatus  }}</div>
            </div>
            <div class="pe-5">
              <div class="font-weight-bold text-caption">作成日</div>
              <div>{{ data.sheetInfo.createdAt }}</div>
            </div>
            <div class="pe-5">
              <div class="font-weight-bold text-caption">作成者</div>
              <div>{{ data.sheetInfo.createdUserName }}</div>
            </div>
          </div>
          <!-- エディター -->
          <div class="editor pt-5">
            <EditorJsContainer
              v-if="init"
              ref="editor"
              :projectId="0"
              :data="loadData"
              :initReadonly="!editing"
              :key="editorKey"
            ></EditorJsContainer>
          </div>
        </div>
      </div>
    </div>

    <!-- 汎用ダイアログ -->
    <yes-no-dialog
      v-if="msg"
      :message="msg"
      v-model="msg"
      yesText="戻る"
      center
      @clickYes="msg = ''; saveDialog = false;"
    />

    <!-- 遷移確認ダイアログ -->
    <yes-no-dialog
      message="編集データが保存されていません。保存しますか？"
      v-model="saveDialog"
      yesText="保存する"
      noText="保存しない"
      center
      persistent
      @clickYes="saveYes()"
      @clickNo="cb()"
    />

  </div>
</template>
<script>
import EditorJsContainer from "@/components/atomic/organisms/EditorJsContainer.vue";
import StrategyPartsRefBar from "@/components/atomic/organisms/StrategyPartsRefBar.vue";
import YesNoDialog from "../atoms/YesNoDialog.vue";
import { RepositoryFactory } from "@/api/RepositoryFactory";
import { mapGetters } from "vuex";
import MasterData from '../../../utils/MasterData';

import { test_data } from "@/views/sample/SampleEditorJS_data";

//https://bt-daisuke-ishii.notion.site/BB-797e41ac4ce34939b05f1fbcdba8612b

export default {
  components: {
    EditorJsContainer,
    StrategyPartsRefBar,
    YesNoDialog,
  },
  props: {
    queryOverviewId: [Number, String],
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loadData: {},
      data: {
        overview: {},
        sheetInfo: {},
      },
      init: false,
      expand: false,
      editing: false,
      msg: "",
      MasterData,

      saveDialog: false,
      cb: null,

      noData: false,
      editorKey: 0,
    };
  },
  computed: {
    ...mapGetters(["project", "hasWritePermission", "userInfo"]),
  },

  async mounted() {
    await this.load();
    window.addEventListener("beforeunload", this.beforeUnload);
    this.init = true;
  },

  destroyed() {
    window.removeEventListener("beforeunload", this.beforeUnload)
  },

  methods: {

    async load() {
      if(this.queryOverviewId) {
        // 既存データがある場合
        const overviewRepository = RepositoryFactory.get('overview')
        const ret = await overviewRepository.fetched(this.queryOverviewId, this.project.id);
        this.data = ret.data.data;

        // プロジェクトとノートが紐づいていない、又は削除されたプロジェクトの場合 this.data.overview?.id = undefined となる
        if(this.data.overview?.projectId !== this.project.id) this.noData = true;

        this.loadData = JSON.parse(this.data.overview.data);
        
        this.editing = false;
      }else {
        // 新規登録の場合
        this.editing = true;
        this.data.overview.inputStatus = '編集中';
      }
    },
    async save() {
      const saveData = await this.$refs.editor.save();

      // １つもブロックが登録されていないときには保存できないようにする。
      // 空のJsonをreadOnly:trueで開くとEditorJSに怒られるので...
      if(!saveData.blocks.length) {
        this.msg = "空のデータを保存することはできません。"
        return false;
      }

      if(!this.data.overview.title) {
        this.msg = "タイトルを入力してください。"
        return false;
      }

      const payload = {
        overview: {
          data: JSON.stringify(saveData),
          title: this.data.overview.title,
          category: this.data.overview.category,
          inputStatus: this.data.overview.inputStatus
        },
        userId: this.userInfo.userId,
      };

      const overviewRepository = RepositoryFactory.get("overview");

      let res;
      if(!!this.queryOverviewId) {
        res = await overviewRepository.updated(this.queryOverviewId, this.project.id, payload);
      } else {
        res = await overviewRepository.create(this.project.id, payload);
      }
      this.data = res.data.data;
      this.loadData = JSON.parse(this.data.overview.data);

      this.toggleEdit()

      // 新規登録した場合は一覧に戻る
      if(!this.queryOverviewId) {
        this.$router.push({name: "StrategyOverview"})
        this.$store.commit("setToast", {msg: '保存しました', color: 'green'});
      }

      return true;
    },

    async saveYes() {
      let rst = await this.save();
      if(!rst){
        // 保存に失敗していたら遷移を中断
        this.cb(false);
        this.cb = null;
        return;
      }
      this.cb();
    },

    toggleEdit() {
      this.editing = !this.editing;
      // this.$refs.editor.toggleReadOnly();
    },

    beforeUnload(e) {
      // 編集中の場合は確認を表示する
      if (this.editing) {
        e.returnValue = ""
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.editing) {
      // コールバックを設定して遷移確認ダイアログを表示
      this.cb = next;
      this.saveDialog = true;
    } else {
      next();
    }
  },
  watch: {
    editing(to, from) {
      // EditorJsでreadonlyを切り替えるとブロックの注釈が消えてしまう不具合への対応
      // 編集状態を切り替えるたびにDOMを再生成する。
      this.editorKey++;
    }
  }
};
</script>
<style lang="scss" scoped>
.strategy-overview-wrap {
  padding-left: 60px;
  padding-right: 60px;
  width: 100%;
  max-width: 1040px;
  min-width: 700px;

  .strategy-overview {

    
    .toolbar {
      position: sticky;
      z-index: 10;
      top: 0;
      left: 0;
    }
  }
}
</style>
